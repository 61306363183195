import React from 'react'
import {Title, P, H6} from '../../Atoms/Typography'
import {Label, LabelInput} from '../../Atoms/Forms'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InvisibleContainer, Baseline, Row} from '../../Layout/Grid'
import {Button, TextButton} from '../../Atoms/Buttons'
import {useAppState} from '../../../state'
import {useNavigation} from 'react-navi'
import {colors} from '../../../sharedComponents/colors'
import {LoginLogo, TitleWrapper} from './LoginComponents'
import {Helmet} from 'react-navi-helmet-async'
import {AuthError} from 'aws-amplify/auth'

interface Form {
  password: string
  confirmPassword: string
  code: string
}
interface Creds {
  email: string
}
const ForgotPasswordConfirm = () => {
  const navi = useNavigation()
  const state = navi.extractState()
  const {email} = state
  const fc = useForm<Form>({mode: 'onChange'})
  const {actions} = useAppState()
  const {t} = useTranslation(['users', 'auth'])

  const {password, confirmPassword, code} = fc.watch()

  const [amplifyError, setAmplifyError] = React.useState<string | undefined>(undefined)

  let codeErrorMessage =
    code && code.length < 6 ? t('common:validation.resetCodeTooShort', 'Code too short') : undefined
  const passwordErrorMessage: string | undefined =
    confirmPassword !== password
      ? t('common:validation.passwordsNotMatching', 'New passwords must match')
      : password && confirmPassword.length < 8
      ? t('common:validation.passwordTooShort', 'New password is too short, must be at least 8 characters')
      : password && (!/[a-z]/.test(password) || !/[A-Z]/.test(password))
      ? t('common:validation.passwordFormatWrong', 'New password must contain both lowercase and uppercase letters')
      : undefined

  const handleConfirm = async () => {
    try {
      await actions
        .confirmPassword({email, password, code})
        .then((response: any) => {
          if (response instanceof AuthError) {
            throw response
          }
          navi.navigate('/login/forgot/complete')
          console.log('Navigation successful')
        })
        .catch(e => {
          const err = e as AuthError
          if (err.name === 'LimitExceededException') {
            console.error('Too many attempts, please try again later')
            setAmplifyError(t('common:validation.tooManyAttempts', 'Too many attempts, please try again later'))
          } else if (err.name === 'CodeMismatchException') {
            console.error('Code incorrect')
            setAmplifyError(t('common:validation.forgotPasswordError', 'Error resetting password'))
          } else {
            console.error('Unknown error')
            setAmplifyError(t('common:validation.forgotPasswordError', 'Error resetting password'))
          }
        })
    } catch (e) {
      const err = e as AuthError
      console.error('ForgotPasswordConfirm handleConfirm error', err)
    }
  }

  return (
    <InvisibleContainer>
      <Helmet title={t('common:routes.confirmForgotPassworReset', 'Confirm forgot password reset')} />
      <LoginLogo />
      <TitleWrapper>
        <Title level={2} color={colors.system.white}>
          {t('auth:labels.forgotPassword', 'Forgot password?')}
        </Title>
      </TitleWrapper>
      <Baseline>
        <P color={colors.system.white}>
          {t(
            'auth:messages.enterConfirmation',
            'Enter confirmation code sent via email and new password to reset your password.'
          )}
        </P>
        <LabelInput
          negative={true}
          autoComplete="one-time-code"
          labelText={t('auth:labels.code', 'Code')}
          name="code"
          id="code"
          type="text"
          ref={fc.register({required: true})}
          errorMsg={codeErrorMessage}
        />
        <H6 style={{color: 'white'}}>
          {t(
            'common:validation.passwordLength',
            'Your password must contain minimum 8 characters, lowercase letters, and uppercase letters.'
          )}
        </H6>
        <LabelInput
          negative={true}
          autoComplete="new-password"
          labelText={t('users:labels.newPassword', 'New password')}
          name="password"
          id="password"
          type="password"
          ref={fc.register({required: true})}
          errorMsg={passwordErrorMessage}
        />
        <LabelInput
          negative={true}
          autoComplete="new-password"
          labelText={t('users:labels.repeatNewPassword', 'Repeat new password')}
          name="confirmPassword"
          id="confirmPassword"
          type="password"
          ref={fc.register({required: true})}
        />
        <Row>
          <TextButton negative={true} onClick={() => navi.navigate('/login')}>
            {t('auth:labels.backToLogin', 'Back to login')}
          </TextButton>
        </Row>
        <Button
          disabled={!!codeErrorMessage || !!passwordErrorMessage || !fc.formState.isValid}
          onClick={handleConfirm}
        >
          {t('common:buttons.resetPassword', 'Reset password')}
        </Button>
        <Row>
          <Label style={{color: colors.system.red}}>{amplifyError}</Label>
        </Row>
      </Baseline>
    </InvisibleContainer>
  )
}

export default ForgotPasswordConfirm
