import React, {useReducer, useEffect, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {Label, Select, Input, TimePicker, DatePicker} from '../../Atoms/Forms'
import {Row, Baseline} from '../../Layout/Grid'
import SelectWeekdayWidget from './SelectWeekWidget'
import SelectMonthWidget from './SelectMonthWidget'
import SelectYearWidget from './SelectYearWidget'
import {format, parseISO, getDate, getDay} from 'date-fns'
import {pick} from 'lodash'
import {useAppState} from '../../../state'
import {getLocale} from '../../Atoms/Utils'
import LateByDaysSelector from './LateByDaysSelector'

const OptionsRow = styled(Row)`
  align-content: flex-start;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 0.75rem;

  > * {
    margin-right: 0.5rem;
    flex: 1;
  }
`

// TODO! Localization

const DATE_FORMAT = 'yyyy-MM-dd'
const TIME_FORMAT = 'HH:mm:ss'

enum ActionType {
  CHANGE_FREQUENCY = 'change_frequency',
  CHANGE_PERIOD_TYPE = 'change_period_type',
  CHANGE_DATE = 'change_date',
  CHANGE_TIME = 'change_time',
  CHANGE_WEEKDAYS = 'change_weekdays',
  CHANGE_MONTH_DAYS = 'change_month_days',
  CHANGE_YEAR_DATES = 'change_year_dates'
}

export enum PeriodType {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}
export interface Schedule {
  frequency: number
  periodType: PeriodType
  startDate: string
  startTime: string
  weekDays?: number[]
  days?: number[]
  dates?: string[]
}

// backend requires weekdays to start from monday, so move sun to 6 and rest to n-1
const handleWeekDays = (date: Date) => (date.getDay() === 0 ? 6 : getDay(date) - 1)

// Reducer to handle state updating
const schedulerReducer = (state: Schedule, action: dispatchAction): Schedule => {
  switch (action.type) {
    case ActionType.CHANGE_FREQUENCY:
      if (action.payload && action.payload > 0) {
        return {...state, frequency: action.payload}
      }
      return {...state, frequency: 1}
    case ActionType.CHANGE_PERIOD_TYPE:
      const date = new Date()
      const defaults = {
        weekDays: [handleWeekDays(date)],
        days: [getDate(date)],
        dates: [format(date, DATE_FORMAT)]
      }
      const newPeriodType: PeriodType = action.payload
      switch (newPeriodType) {
        case PeriodType.WEEK:
          return {...state, periodType: action.payload, days: undefined, dates: undefined, weekDays: defaults.weekDays}
        case PeriodType.MONTH:
          return {...state, periodType: action.payload, days: defaults.days, dates: undefined, weekDays: undefined}
        case PeriodType.YEAR:
          return {...state, periodType: action.payload, days: undefined, dates: defaults.dates, weekDays: undefined}
        default:
          return state
      }
    case ActionType.CHANGE_DATE:
      return {...state, startDate: action.payload}
    case ActionType.CHANGE_TIME:
      return {...state, startTime: action.payload}
    case ActionType.CHANGE_WEEKDAYS:
      return {...state, weekDays: action.payload}
    case ActionType.CHANGE_MONTH_DAYS:
      return {...state, days: action.payload}
    case ActionType.CHANGE_YEAR_DATES:
      return {...state, dates: action.payload}
    default:
      return state
  }
}

interface dispatchAction {
  type: ActionType
  payload: any
}
interface SchedulerProps {
  schedule?: Schedule
  onChange?: (schedule: Schedule) => void
  lateByDays?: number
  onLateByDaysChange?: (lateByDays: number) => void
}
const Scheduler = ({schedule, onChange, lateByDays, onLateByDaysChange}: SchedulerProps) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const date = new Date()
  const {site} = useAppState().state
  const superUser = state.me?.accessRights.superuser
  const repeatTypeOptions = [
    {option: t('common:labels.schedulerWeek', 'Week'), id: 'week'},
    {option: t('common:labels.schedulerMonth', 'Month'), id: 'month'},
    {option: t('common:labels.schedulerYear', 'Year'), id: 'year'}
  ]

  const defaultSchedule = {
    frequency: 1,
    periodType: PeriodType.WEEK,
    weekDays: [handleWeekDays(date)],
    days: [getDate(date)],
    dates: [format(date, DATE_FORMAT)],
    startDate: format(date, DATE_FORMAT),
    startTime: format(date, TIME_FORMAT)
  }

  const [scheduler, dispatch] = useReducer(schedulerReducer, schedule || defaultSchedule)

  // event handlers
  const frequencyChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: ActionType.CHANGE_FREQUENCY, payload: event.target.value})
  }
  const periodTypeChanged = (typeIndex: number) => {
    dispatch({type: ActionType.CHANGE_PERIOD_TYPE, payload: repeatTypeOptions[typeIndex].id})
  }
  const timeChanged = (time: Date) => {
    dispatch({type: ActionType.CHANGE_TIME, payload: format(time, TIME_FORMAT)})
  }
  const dateChanged = (date: Date) => {
    dispatch({type: ActionType.CHANGE_DATE, payload: format(date, DATE_FORMAT)})
  }
  const weekDaysChanged = (weekDays: number[]) => {
    dispatch({type: ActionType.CHANGE_WEEKDAYS, payload: weekDays})
  }
  const monthDaysChanged = (monthDays: number[]) => {
    dispatch({type: ActionType.CHANGE_MONTH_DAYS, payload: monthDays})
  }
  const yearDaysChanged = (yearDays: string[]) => {
    dispatch({type: ActionType.CHANGE_YEAR_DATES, payload: yearDays})
  }

  // inform when the schedule gets updated
  const handleChange = useCallback(
    (schedule: Schedule) => {
      if (onChange) {
        switch (schedule.periodType) {
          case PeriodType.WEEK: {
            onChange(pick(schedule, ['frequency', 'periodType', 'startDate', 'startTime', 'weekDays']))
            break
          }
          case PeriodType.MONTH: {
            onChange(pick(schedule, ['frequency', 'periodType', 'startDate', 'startTime', 'days']))
            break
          }
          case PeriodType.YEAR: {
            onChange(pick(schedule, ['frequency', 'periodType', 'startDate', 'startTime', 'dates']))
            break
          }
        }
      }
    },
    [onChange]
  )

  const handleLateByDaysChange = (lateByDays: number) => {
    if (onLateByDaysChange) {
      onLateByDaysChange(lateByDays)
    }
  }

  useEffect(() => {
    handleChange(scheduler)
  }, [scheduler, handleChange])

  const getStartDate = () => {
    return parseISO(`${scheduler.startDate}T${scheduler.startTime}`)
  }

  return (
    <Baseline>
      <div>
        <Label>{t('tasks:labels.repeatsEvery', 'Repeats every')}</Label>
        <OptionsRow>
          <Input value={scheduler.frequency} onChange={frequencyChanged} type="number" min="1" id="repeatFrequency" />
          <Select
            options={repeatTypeOptions}
            returnId
            nativeProps={{
              name: 'repeatType',
              onChange(e) {
                periodTypeChanged(repeatTypeOptions.findIndex(o => o.id === e.target.value))
              },
              value: repeatTypeOptions[repeatTypeOptions.findIndex(o => o.id === scheduler.periodType)].id
            }}
            id="repeatTypeSelect"
          />
          <TimePicker locale={getLocale(site!.locale)} date={getStartDate()} onChange={timeChanged} />
        </OptionsRow>
      </div>

      {scheduler.periodType === PeriodType.WEEK ? (
        <SelectWeekdayWidget selectedWeekDays={scheduler.weekDays} onChange={weekDaysChanged}></SelectWeekdayWidget>
      ) : null}

      {scheduler.periodType === PeriodType.MONTH ? (
        <SelectMonthWidget days={scheduler.days} onChange={monthDaysChanged}></SelectMonthWidget>
      ) : null}

      {scheduler.periodType === PeriodType.YEAR ? (
        <SelectYearWidget dates={scheduler.dates} onChange={yearDaysChanged}></SelectYearWidget>
      ) : null}
      <div>
        <Label>{t('tasks:labels.startingDate', 'Starting date')}</Label>
        <DatePicker date={getStartDate()} onChange={dateChanged} locale={getLocale(site!.locale)}></DatePicker>
      </div>
      {superUser ? <LateByDaysSelector lateByDays={lateByDays} onLateByDaysChange={handleLateByDaysChange} /> : null}
    </Baseline>
  )
}

export default Scheduler
