import React from 'react'
import {IconProps} from './Icons'

/**
 * Add icons that indicate an action the user can perform here
 */

export const IconClose = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M21.01 3.7l-.71-.71-8.3 8.3-8.3-8.3-.71.71 8.3 8.3-8.3 8.3.71.71 8.3-8.3 8.3 8.3.71-.71-8.3-8.3z" />
  </svg>
)

export const IconCloseSmall = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M16.65 8.06l-.7-.71L12 11.29 8.06 7.35l-.71.71L11.29 12l-3.94 3.95.71.7L12 12.71l3.95 3.94.7-.7L12.71 12z" />
  </svg>
)

export const IconDelete = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M9.58 8.49h1v9h-1zM13.42 8.49h1v9h-1z" />
    <path d="M20.66 4.82H12.5V2.11h-1v2.71H3.34v1h2v15.33h13.32V5.82h2v-1zm-3 15.34H6.34V5.82h11.32v14.34z" />
  </svg>
)

export const IconArrowRight = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M8.78 21.01l-.71-.71 8.3-8.3-8.3-8.3.71-.71 9 9.01z" />
  </svg>
)

export const IconArrowSmallRight = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M9.9 16.91l-.71-.71L13.4 12 9.19 7.8l.71-.71L14.81 12z" />
  </svg>
)

export const IconCollapse = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M20.3 16.68L12 8.38l-8.3 8.3-.71-.71 9.01-9 9.01 9z" />
  </svg>
)

export const IconExpand = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M12 17.03l-9.01-9 .71-.71 8.3 8.3 8.3-8.3.71.71z" />
  </svg>
)

export const IconBack = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M19 11.51L6.21 11.5l6.38-6.38-.71-.71L4.3 12l7.58 7.59.71-.71-6.38-6.38 12.79.01z" />
  </svg>
)

export const IconVideo = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M21.16 19.39H2.84V4.61h18.31v14.78zm-17.32-1h16.31V5.61H3.84v12.78z" />
    <path d="M15.63 12l-5.28-3.05v6.1z" />
  </svg>
)

export const IconPhoto = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M11.99 17.95c-2.3 0-4.18-1.87-4.18-4.17S9.68 9.6 11.99 9.6c2.3 0 4.18 1.87 4.18 4.18 0 2.3-1.88 4.17-4.18 4.17zm0-7.35c-1.75 0-3.18 1.42-3.18 3.18 0 1.75 1.42 3.17 3.18 3.17a3.18 3.18 0 003.18-3.17c0-1.75-1.43-3.18-3.18-3.18z" />
    <path d="M20.16 10.56v9.6H3.84V8.77h2.31l2.9-2.93h6.28v-1h-6.7l-2.9 2.93H2.84v13.39h18.32v-10.6zM23.9 4.84h-2.74V2.1h-1v2.74h-2.74v1h2.74v2.74h1V5.84h2.74z" />
  </svg>
)

export const IconGallery = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M23.9 4.84h-2.74V2.1h-1v2.74h-2.74v1h2.74v2.74h1V5.84h2.74zM16.66 16.82l-2.04-2.04-1.53 1.53-2.76-2.79-3.04 3.04-.7-.71 3.75-3.75 2.76 2.79 1.52-1.52 2.75 2.75z" />
    <path d="M20.16 10.56v9.6H3.84V5.84h11.59v-1H2.84v16.32h18.32v-10.6z" />
  </svg>
)

export const IconLink = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M11.75 16.91h-4.9a3.44 3.44 0 110-6.88h.7v1h-.7c-1.35 0-2.44 1.1-2.44 2.44s1.1 2.44 2.44 2.44h4.9c1.35 0 2.44-1.1 2.44-2.44s-1.1-2.44-2.44-2.44h-.7v-1h.7c1.9 0 3.44 1.54 3.44 3.44.01 1.9-1.54 3.44-3.44 3.44z" />
    <path d="M17.15 13.97h-.7v-1h.7c1.35 0 2.44-1.1 2.44-2.44s-1.1-2.44-2.44-2.44h-4.9c-1.35 0-2.44 1.1-2.44 2.44s1.1 2.44 2.44 2.44h.7v1h-.7a3.44 3.44 0 110-6.88h4.9a3.44 3.44 0 110 6.88z" />
  </svg>
)

export const IconSettings = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M13.84 21.16h-3.68l-.4-2.42a7.27 7.27 0 01-2.48-1.44l-2.29.87-1.84-3.19 1.9-1.55c-.1-.48-.15-.96-.15-1.43s.05-.95.15-1.43l-1.9-1.55 1.84-3.19 2.29.87C8 6.06 8.85 5.57 9.77 5.27l.4-2.42h3.68l.4 2.42c.91.3 1.76.79 2.48 1.43l2.29-.87 1.84 3.19-1.9 1.55c.1.48.15.96.15 1.43s-.05.95-.15 1.43l1.9 1.55-1.84 3.19-2.29-.87c-.72.64-1.57 1.13-2.48 1.43l-.41 2.43zm-2.83-1h1.98l.36-2.2.31-.09c.97-.27 1.87-.79 2.6-1.5l.23-.22 2.09.79.99-1.71-1.73-1.42.08-.31c.13-.51.19-1.01.19-1.5s-.07-.99-.19-1.5l-.08-.31 1.73-1.41-.99-1.71-2.09.79-.23-.22a6.131 6.131 0 00-2.6-1.5l-.31-.09-.36-2.2h-1.98l-.36 2.2-.31.09c-.98.27-1.87.79-2.6 1.5l-.22.21-2.09-.79-.99 1.72 1.73 1.41-.08.31a6.048 6.048 0 000 3l.08.31-1.73 1.42.99 1.71 2.09-.79.23.22c.72.71 1.62 1.22 2.6 1.5l.31.09.35 2.2z" />
    <path d="M12 15.97c-2.19 0-3.97-1.78-3.97-3.97S9.81 8.03 12 8.03s3.97 1.78 3.97 3.97-1.78 3.97-3.97 3.97zm0-6.94a2.97 2.97 0 100 5.94 2.97 2.97 0 000-5.94z" />
  </svg>
)

export const IconMore = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M12 7.95a2.5 2.5 0 010-5 2.5 2.5 0 010 5zm0-4c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zM12 14.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5zm0-4c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zM12 21.04a2.5 2.5 0 010-5 2.5 2.5 0 010 5zm0-4c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />
  </svg>
)

export const IconDocument = (props: IconProps) => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M16.16 2.84H2.84v18.31h18.31v-13l-4.99-5.31zm.28 1.76l3.06 3.24h-3.06V4.6zM3.84 20.16V3.84h11.6v5h4.71v11.31H3.84z" />
    <path d="M6.96 7.24h5v1h-5zM6.95 11.57h10v1h-10zM6.95 15.9h10v1h-10z" />
  </svg>
)
export const IconSearch = (props: IconProps) => (
  <svg viewBox="0 -960 960 960" width="1em" height="1em" {...props} fill="currentcolor">
    <path d="M785.23-154.08 529.15-410.15q-29.69 26.82-69.26 40.91-39.56 14.09-79.58 14.09-95.59 0-161.79-66.03-66.21-66.03-66.21-161t66.03-161.13q66.03-66.15 160.94-66.15t161.51 66.06q66.59 66.07 66.59 160.96 0 41.21-14.76 80.82-14.77 39.62-41.24 69.24l256.31 255.07-22.46 23.23ZM380.08-385.92q82.66 0 139.6-56.73t56.94-139.66q0-82.92-56.94-139.65-56.94-56.73-139.6-56.73-82.86 0-139.93 56.73-57.07 56.73-57.07 139.65 0 82.93 57.07 139.66 57.07 56.73 139.93 56.73Z" />
  </svg>
)

const defaultProps = {
  color: 'inherit',
  width: '24px',
  height: '24px'
}

IconCloseSmall.defaultProps = defaultProps
IconDelete.defaultProps = defaultProps
IconSettings.defaultProps = defaultProps
IconClose.defaultProps = defaultProps
IconArrowRight.defaultProps = defaultProps
IconArrowSmallRight.defaultProps = defaultProps

IconCollapse.defaultProps = defaultProps
IconExpand.defaultProps = defaultProps
IconVideo.defaultProps = defaultProps
IconPhoto.defaultProps = defaultProps
IconDocument.defaultProps = defaultProps
IconGallery.defaultProps = defaultProps
IconLink.defaultProps = defaultProps
IconBack.defaultProps = defaultProps
IconMore.defaultProps = defaultProps
IconSearch.defaultProps = defaultProps
