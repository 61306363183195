import React from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../../../colors'
import {Card, CardWidthType} from '../../atoms/Card'
import {IconPalette, SharedIcon} from '../../atoms/Icon'
import {Text} from '../../atoms/Text'
import {ApplianceData, ApplianceDataProps} from '../ApplianceData'
import {ApplianceStateTag} from './ApplianceStateTag'

export interface ApplianceCardProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: IconPalette
  data: (ApplianceDataProps | React.ReactNode)[]
  name: string
  disabled?: boolean
  tagLabel?: string
  activeAlarmCount?: number
  compact?: boolean
}

export const ApplianceCard: React.FC<ApplianceCardProps> = ({
  icon,
  data,
  name,
  disabled,
  tagLabel,
  activeAlarmCount,
  compact,
  ...rest
}) => {
  const textColor = disabled ? colors.system.grey_1 : colors.brand.cobalt
  const dividerColor = disabled ? colors.system.grey_3 : colors.system.grey_5
  const widthType = data.length > 1 ? CardWidthType.Wide : CardWidthType.Default
  const applianceIconSize = compact ? 2 : 2.875
  const alarmIconSize = compact ? 0.875 : 1
  const borderColor = activeAlarmCount != undefined && activeAlarmCount > 0 ? colors.system.red : undefined
  return (
    <Card widthType={widthType} {...rest} compact={compact} borderColor={borderColor}>
      <ApplianceCardContent compact={compact}>
        <ApplianceCardIcon>
          <SharedIcon icon={icon} width={`${applianceIconSize}em`} height={`${applianceIconSize}em`} fill={textColor} />
        </ApplianceCardIcon>
        <ApplianceCardData compact={compact}>
          {data.map((d, i) => {
            const applianceData = React.isValidElement(d) ? (
              React.cloneElement(d, {key: i})
            ) : (
              <StyledApplianceData key={i} disabled={disabled} {...d} compact={compact} />
            )
            return i === 0 || compact ? (
              applianceData
            ) : (
              <React.Fragment key={i}>
                <ApplianceCardDataDivider backgroundColor={dividerColor} />
                {applianceData}
              </React.Fragment>
            )
          })}
        </ApplianceCardData>
        <ApplianceCardInfo>
          <ApplianceCardInfoText size={compact ? 'M' : 'L'} color={textColor} strong>
            {name}
          </ApplianceCardInfoText>
        </ApplianceCardInfo>
        <ApplianceCardStateAndAlarms>
          <ApplianceStateTag label={tagLabel} disabled={disabled} compact={compact} />
          {activeAlarmCount !== undefined && activeAlarmCount > 0 && (
            <ApplianceCardAlarms>
              <SharedIcon
                icon={IconPalette.Alarm}
                width={`${alarmIconSize}em`}
                height={`${alarmIconSize}em`}
                fill={colors.system.red}
              />
              <Text size={compact ? 'S' : 'M'} color={colors.system.red} strong>
                {activeAlarmCount}
              </Text>
            </ApplianceCardAlarms>
          )}
        </ApplianceCardStateAndAlarms>
      </ApplianceCardContent>
    </Card>
  )
}

const ApplianceCardContent = styled.div<{compact?: boolean}>(
  ({compact}) => css`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: minmax(${compact ? 2 : 4}rem, auto) 1fr minmax(${compact ? 1.5 : 2}rem, auto);
    grid-template-columns: auto 1fr;
    grid-template-areas:
      'icon data'
      'info info'
      'state state';
    gap: ${compact ? 0.75 : 1}rem;
  `
)

const ApplianceCardIcon = styled.div`
  grid-area: icon;
`

const ApplianceCardData = styled.div<{compact?: boolean}>(
  ({compact}) => css`
    grid-area: data;
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    align-items: start;
    gap: ${compact ? 1 : 1.5}rem;
  `
)

const StyledApplianceData = styled(ApplianceData)`
  cursor: pointer;
`

const ApplianceCardDataDivider = styled.div<{backgroundColor?: string}>`
  width: 1px;
  height: 100%;
  background-color: ${props => props.backgroundColor || colors.system.grey_5};
`

const ApplianceCardInfo = styled.div`
  width: 100%;
  grid-area: info;
  display: grid;
  align-content: end;
  gap: 0.25rem;
`

const ApplianceCardInfoText = styled(Text)<{lines?: number}>`
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: ${({lines = 2}) => lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
`

const ApplianceCardStateAndAlarms = styled.div`
  grid-area: state;
  display: grid;
  grid-template: auto / auto 1fr;
  align-items: center;
`

const ApplianceCardAlarms = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 0.125rem;
  justify-content: end;
`
