import React, {Suspense, ReactNode, useEffect} from 'react'
import {
  MainContentContainer,
  LoginContentContainer,
  AppWrapper,
  LoginWrapper,
  InfoWrapper,
  InfoHeaderContainer,
  InfoMainContentContainer,
  Newsbar,
  Notificationbar,
  NoMenuContentContainer
} from './Components/Layout/Layout'
import AdminView from './Components/Views/Admin/AdminView'
import EditChainView from './Components/Views/Admin/EditChain'
import EditTasks from './Components/Views/EditTasks'
import SettingsView from './Components/Views/Settings/SettingsView'
import AlarmSettingsView from './Components/Views/Settings/Alarms/AlarmSettingsView'
import EditAlarmRule from './Components/Views/Settings/Alarms/EditAlarmRule'
import DocumentsView from './Components/Views/DocumentsAndInstructions/Documents/DocumentsView'
import EditUserAccountSettings from './Components/Views/Users/EditUserAccountSettings'
import EditDocument from './Components/Views/DocumentsAndInstructions/Documents/EditDocument'
import DocumentFolderSettings from './Components/Views/DocumentsAndInstructions/Documents/DocumentFolderSettings'
import EquipmentSettingsView from './Components/Views/Equipment/EquipmentSettingsView'
import EquipmentTypeSettings from './Components/Views/Equipment/EquipmentTypeSettings'
import EditEquipmentType from './Components/Views/Equipment/EditEquipmentType'
import EquipmentTypeSelect from './Components/Views/Equipment/EquipmentTypeSelect'
import EditEquipment from './Components/Views/Equipment/EditEquipment'
import EditDocumentFolder from './Components/Views/DocumentsAndInstructions/Documents/EditDocumentFolder'
import {mount, route, Matcher, redirect, map} from 'navi'
import {Router, View, NotFoundBoundary, useLoadingRoute} from 'react-navi'
import {createPortal} from 'react-dom'
import {Puff} from 'svg-loaders-react'
import {SelectTaskType, CreateTaskSelector} from './Components/Views/Tasks/CreateTask'

import {useAppState} from './state'
import Login from './Components/Views/Login/Login'
import ForgotPassword from './Components/Views/Login/ForgotPassword'
import ForgotPasswordConfirm from './Components/Views/Login/ForgotPasswordConfirm'
import ForgotPasswordComplete from './Components/Views/Login/ForgotPasswordComplete'
import ForceResetPassword from './Components/Views/Login/ForceResetPassword'

import EditTaskGroups from './Components/Views/EditTaskGroups'
import EditTaskGroup from './Components/Views/Tasks/EditTaskGroup'

import ReportsView from './Components/Views/Dashboard/DashboardView'
import TestQuicksightReportView from './Components/Views/Dashboard/TestQuicksightDashboardView'
import Errors400 from './Components/Views/Errors/ErrorPages'

import UsersView from './Components/Views/Users/UsersView'
import UsersEditView from './Components/Views/Users/UsersEditView'
import LocationsView from './Components/Views/Settings/Locations/LocationsView'
import TagManager from 'react-gtm-module'
import {v4 as uuid} from 'uuid'
import {State} from './state/state'
import EditSite from './Components/Views/Admin/EditSite'
import AddUserView from './Components/Views/Users/AddUserView'
import EditOrganisationLevel from './Components/Views/Admin/EditOrganisationLevel'

import InfoHeader from './Components/Views/Info/InfoHeader'

import NewsTicker from './Components/Organisms/NewsTicker/NewsTicker'

import {NotificationList} from './Components/Atoms/Notifications'
import i18next from './config/int'

import {LoginLanguageSelect} from './Components/Organisms/Language/LanguageSelect'

import {TasksPage, AppliancesPage, AlarmsPage, HomePage, InfoAppliancesPage, InfoViewOverviewPage} from './pages'

import {NavigationMenu} from './Components/Views/Navigation/Menu'
import {InstructionsView} from './Components/Views/DocumentsAndInstructions/Instructions/InstructionsView'
import {InstructionsFolderSettings} from './Components/Views/DocumentsAndInstructions/Instructions/InstructionsFolderSettings'
import {EditInstructionsFolder} from './Components/Views/DocumentsAndInstructions/Instructions/EditInstructionsFolder'
import {EditInstructionsDocument} from './Components/Views/DocumentsAndInstructions/Instructions/EditInstructionsDocument'
import EditLocation from './Components/Views/Admin/EditLocation'
import MessageSettingsView from './Components/Views/Messages/MessageSettingsView'
import EditMessageView from './Components/Views/Messages/EditMessageView'
import MultipleSiteClosingPeriodPage from './Components/Views/Settings/Locations/MultipleSiteClosingPeriod'
import * as AmplifyAuth from 'aws-amplify/auth'
import {Amplify} from 'aws-amplify'

interface Context {
  currentUser: AmplifyAuth.AuthUser
  forcePasswordResetUser: boolean
  state: State
}
/**
 * Wrapper for routes that needs authentication and for admin user only
 * @param matcher route or map from Navi
 */
function adminOnlyRoute(matcher: Matcher<Context>) {
  return map<Context>((_request, context) => {
    return context.currentUser
      ? context.state.me?.accessRights.superuser === true
        ? gtmDataLayer(matcher)
        : redirect('/')
      : redirect('/login')
  })
}
/**
 * Wrapper for routes that needs authentication and for admin base screens only (views without sidebar, thus no selected chain)
 * @param matcher route or map from Navi
 */
function adminBaseRoute(matcher: Matcher<Context>) {
  return map<Context>((_request, context) => {
    return context.currentUser
      ? context.state.me?.accessRights.superuser === true && context.state.selectedChainId === undefined
        ? gtmDataLayer(matcher)
        : redirect('/')
      : redirect('/login')
  })
}
/**
 * Wrapper for routes that needs authntication
 * @param matcher route or map from Navi
 */
function authRoute(matcher: Matcher<Context>) {
  return map<Context>((_request, context) => {
    return context.currentUser
      ? (context.state.me?.accessRights.superuser === true && context.state.selectedChainId !== undefined) ||
        context.state.me?.accessRights.superuser === false
        ? gtmDataLayer(matcher)
        : redirect('/admin')
      : redirect('/login')
  })
}
/**
 * Wrapper for routes that should not be shown to authenticated users
 * @param matcher route or map from Navi
 */
function unauthRoute(matcher: Matcher<Context>) {
  return map<Context>((_request, context) => {
    if (!context.currentUser) {
      if (context.forcePasswordResetUser) {
        return redirect('/login/reset')
      } else {
        return gtmDataLayer(matcher)
      }
    } else {
      return redirect('/')
    }
  })
}
/**
 * Wrapper for Google Tag Manager
 * @param matcher route or map from Navi
 */
function gtmDataLayer(matcher: Matcher<Context>) {
  return map<Context>((_request, _context) => {
    const tagManagerDataLayer = {
      dataLayer: {
        event: 'pageView'
      }
      //dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerDataLayer)
    return matcher
  })
}

interface LayoutProps {
  children: ReactNode
  showNews?: boolean
  showExtServices?: boolean
}

const LoginLayout = ({children}: {children: ReactNode}) => {
  return (
    <LoginWrapper>
      <NoMenuLayout>
        <Suspense fallback={null}>
          <LoginLanguageSelect />
          <LoginContentContainer>{children}</LoginContentContainer>
        </Suspense>
      </NoMenuLayout>
    </LoginWrapper>
  )
}

const AdminViewLayout = ({children}: {children: ReactNode}) => {
  return (
    <AppWrapper>
      <NavigationMenu />
      <Notificationbar>
        <NotificationList />
      </Notificationbar>
      <Layout>
        <Suspense fallback={null}>{children}</Suspense>
      </Layout>
    </AppWrapper>
  )
}

const InfoLayout = ({children}: {children: ReactNode}) => {
  const loading = useLoadingRoute()
  return (
    <InfoWrapper>
      <InfoHeaderContainer>
        <InfoHeader></InfoHeader>
      </InfoHeaderContainer>
      <InfoMainContentContainer id="main-content">
        <Loader show={!!loading} />
        <Suspense fallback={null}>{children}</Suspense>
      </InfoMainContentContainer>
      <Newsbar>
        <NewsTicker />
      </Newsbar>
    </InfoWrapper>
  )
}

const MenuLayout = ({children, showNews, showExtServices}: LayoutProps) => {
  return (
    <AppWrapper>
      <NavigationMenu />
      <Notificationbar>
        <NotificationList />
      </Notificationbar>
      <Layout>
        <Suspense fallback={null}>{children}</Suspense>
      </Layout>
      <Newsbar>{showNews && <NewsTicker />}</Newsbar>
    </AppWrapper>
  )
}

const routeData = mount({
  '/login': unauthRoute(
    route(req => ({
      view: (
        <LoginLayout>
          <Login email={req.params.email} password={req.params.password} />
        </LoginLayout>
      )
    }))
  ),
  '/login/reset': route(req => ({
    view: (
      <LoginLayout>
        <ForceResetPassword email={req.params.email} />
      </LoginLayout>
    )
  })),
  '/login/forgot': unauthRoute(
    route({
      view: (
        <LoginLayout>
          <ForgotPassword />
        </LoginLayout>
      )
    })
  ),
  '/login/forgot/confirm': unauthRoute(
    route(req => ({
      view: (
        <LoginLayout>
          <ForgotPasswordConfirm />
        </LoginLayout>
      )
    }))
  ),
  '/login/forgot/complete': unauthRoute(
    route({
      view: (
        <LoginLayout>
          <ForgotPasswordComplete />
        </LoginLayout>
      )
    })
  ),
  '/': authRoute(
    route({
      view: (
        <MenuLayout showNews showExtServices>
          <HomePage />
        </MenuLayout>
      )
    })
  ),
  '/admin': adminBaseRoute(
    route({
      view: (
        <AdminViewLayout>
          <AdminView />
        </AdminViewLayout>
      )
    })
  ),
  '/create/chain': adminBaseRoute(
    route({
      view: (
        <AdminViewLayout>
          <EditChainView />
        </AdminViewLayout>
      )
    })
  ),
  '/edit/chain/:chainId': adminBaseRoute(
    route(req => ({
      view: (
        <AdminViewLayout>
          <EditChainView id={req.params.chainId} />
        </AdminViewLayout>
      )
    }))
  ),
  '/admin/edit/site/:siteId': adminBaseRoute(
    route(req => ({
      view: (
        <AdminViewLayout>
          <EditSite id={req.params.siteId} />
        </AdminViewLayout>
      )
    }))
  ),
  '/admin/create/site': adminBaseRoute(
    route(req => ({
      view: (
        <AdminViewLayout>
          <EditSite />
        </AdminViewLayout>
      )
    }))
  ),
  '/settings': authRoute(
    route({
      view: (
        <MenuLayout>
          <SettingsView />
        </MenuLayout>
      )
    })
  ),
  '/settings/useraccount': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditUserAccountSettings />
        </MenuLayout>
      )
    })
  ),
  '/settings/users': authRoute(
    route({
      view: (
        <MenuLayout>
          <UsersView />
        </MenuLayout>
      )
    })
  ),
  '/settings/users/add': authRoute(
    route({
      view: (
        <MenuLayout>
          <AddUserView />
        </MenuLayout>
      )
    })
  ),
  '/settings/users/edit/:id': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <UsersEditView id={req.params.id} />
        </MenuLayout>
      )
    }))
  ),
  '/settings/locations': authRoute(
    route({
      view: (
        <MenuLayout>
          <LocationsView />
        </MenuLayout>
      )
    })
  ),
  '/settings/locations/add': adminOnlyRoute(
    route({
      view: (
        <MenuLayout>
          <EditSite />
        </MenuLayout>
      )
    })
  ),
  '/settings/locations/add/locations': adminOnlyRoute(
    route({
      view: (
        <MenuLayout>
          <EditLocation />
        </MenuLayout>
      )
    })
  ),
  '/settings/locations/addMultipleSiteClosingPeriod': adminOnlyRoute(
    route({
      view: (
        <MenuLayout>
          <MultipleSiteClosingPeriodPage />
        </MenuLayout>
      )
    })
  ),
  '/settings/locations/edit/:type/:id': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditOrganisationLevel type={req.params.type} id={req.params.id} />
        </MenuLayout>
      )
    }))
  ),
  '/settings/alarms': authRoute(
    route({
      view: (
        <MenuLayout>
          <AlarmSettingsView />
        </MenuLayout>
      )
    })
  ),
  '/settings/alarms/create': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditAlarmRule />
        </MenuLayout>
      )
    })
  ),
  '/settings/alarms/edit/:alarmRuleId': authRoute(
    route(async (req, context: any) => {
      const data = await context.actions.getAlarmSetting(req.params.alarmRuleId)
      return {
        view: (
          <MenuLayout>
            <EditAlarmRule defaultValues={data} key={uuid()} />
          </MenuLayout>
        )
      }
    })
  ),
  '/settings/messages': authRoute(
    route({
      view: (
        <MenuLayout>
          <MessageSettingsView />
        </MenuLayout>
      )
    })
  ),
  '/settings/messages/:id': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditMessageView messageId={req.params.id} />
        </MenuLayout>
      )
    }))
  ),
  '/settings/messages/create': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditMessageView />
        </MenuLayout>
      )
    })
  ),
  '/tasks': authRoute(
    route({
      view: (
        <MenuLayout>
          <TasksPage />
        </MenuLayout>
      )
    })
  ),
  '/appliances': authRoute(
    route({
      view: (
        <MenuLayout>
          <AppliancesPage />
        </MenuLayout>
      )
    })
  ),
  '/appliances/:applianceId': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <AppliancesPage applianceId={req.params.applianceId} {...req} />
        </MenuLayout>
      )
    }))
  ),
  '/documents': authRoute(
    route({
      view: (
        <MenuLayout>
          <DocumentsView />
        </MenuLayout>
      )
    })
  ),
  '/documents/:documentId': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditDocument id={req.params.documentId} />
        </MenuLayout>
      )
    }))
  ),
  '/create/document': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditDocument />
        </MenuLayout>
      )
    })
  ),
  '/documents/folders': authRoute(
    route({
      view: (
        <MenuLayout>
          <DocumentFolderSettings />
        </MenuLayout>
      )
    })
  ),
  '/documents/folders/:folderId': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditDocumentFolder id={req.params.folderId} />
        </MenuLayout>
      )
    }))
  ),
  '/create/documentFolder': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditDocumentFolder />
        </MenuLayout>
      )
    })
  ),
  '/alarms': authRoute(
    route({
      view: (
        <MenuLayout>
          <AlarmsPage />
        </MenuLayout>
      )
    })
  ),
  '/edit/tasks': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditTasks />
        </MenuLayout>
      )
    })
  ),
  '/edit/taskGroups': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditTaskGroups />
        </MenuLayout>
      )
    })
  ),
  '/edit/taskGroup/:groupId': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditTaskGroup id={req.params.groupId} />
        </MenuLayout>
      )
    }))
  ),
  '/create/taskGroup': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditTaskGroup />
        </MenuLayout>
      )
    })
  ),
  '/edit/task/:taskKey/:taskId': authRoute(
    route(async (req, context: any) => {
      const data = await context.actions.getTask(req.params.taskId)
      return {
        view: (
          <MenuLayout>
            <CreateTaskSelector taskKey={req.params.taskKey} defaultValues={data} />
          </MenuLayout>
        )
      }
    })
  ),
  '/create/task': authRoute(
    route({
      view: (
        <MenuLayout>
          <SelectTaskType />
        </MenuLayout>
      )
    })
  ),
  '/create/task/:taskKey': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <CreateTaskSelector taskKey={req.params.taskKey} />
        </MenuLayout>
      )
    }))
  ),
  '/copy/task/:taskKey/:taskId': authRoute(
    route(async (req, context: any) => {
      const data = await context.actions.getTask(req.params.taskId)

      // clean id references
      const dataCopy = {...data, id: undefined}
      if (dataCopy.schedule) {
        dataCopy.schedule.id = undefined
      }

      return {
        view: (
          <MenuLayout>
            <CreateTaskSelector taskKey={req.params.taskKey} defaultValues={dataCopy} key={uuid()} />
          </MenuLayout>
        )
      }
    })
  ),
  '/appliance': authRoute(
    route({
      view: (
        <MenuLayout>
          <EquipmentSettingsView />
        </MenuLayout>
      )
    })
  ),
  '/edit/appliance/:siteId/:equipmentId': authRoute(
    route(async (req, context: any) => {
      const data = await context.actions.getEquipment({applianceId: req.params.equipmentId, siteId: req.params.siteId})
      return {
        view: (
          <MenuLayout>
            <EditEquipment defaultValues={data} key={uuid()} />
          </MenuLayout>
        )
      }
    })
  ),
  '/create/appliance/:typeKey': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditEquipment typeKey={req.params.typeKey} key={uuid()} />
        </MenuLayout>
      )
    }))
  ),
  '/create/appliance': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EquipmentTypeSelect key={uuid()} />
        </MenuLayout>
      )
    }))
  ),
  '/appliance/types': authRoute(
    route({
      view: (
        <MenuLayout>
          <EquipmentTypeSettings />
        </MenuLayout>
      )
    })
  ),
  '/edit/appliance/types/:typeId': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditEquipmentType id={req.params.typeId} />
        </MenuLayout>
      )
    }))
  ),
  '/create/appliance/types': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditEquipmentType />
        </MenuLayout>
      )
    }))
  ),
  '/dashboard': authRoute(
    route({
      view: (
        <MenuLayout>
          <ReportsView />
        </MenuLayout>
      )
    })
  ),
  '/quicksight': authRoute(
    route({
      view: (
        <MenuLayout>
          <TestQuicksightReportView />
        </MenuLayout>
      )
    })
  ),
  '/instructions': authRoute(
    route({
      view: (
        <MenuLayout>
          <InstructionsView />
        </MenuLayout>
      )
    })
  ),
  '/instructions/folders': authRoute(
    route({
      view: (
        <MenuLayout>
          <InstructionsFolderSettings />
        </MenuLayout>
      )
    })
  ),
  '/create/instructionsFolder': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditInstructionsFolder />
        </MenuLayout>
      )
    })
  ),
  '/instructions/folders/:folderId': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditInstructionsFolder id={req.params.folderId} />
        </MenuLayout>
      )
    }))
  ),
  '/create/instructionsDocument': authRoute(
    route({
      view: (
        <MenuLayout>
          <EditInstructionsDocument />
        </MenuLayout>
      )
    })
  ),
  '/instructions/:documentId': authRoute(
    route(req => ({
      view: (
        <MenuLayout>
          <EditInstructionsDocument id={req.params.documentId} />
        </MenuLayout>
      )
    }))
  ),
  '/info': authRoute(
    route({
      view: (
        <InfoLayout>
          <InfoViewOverviewPage />
        </InfoLayout>
      )
    })
  ),
  '/info/appliances': authRoute(
    route({
      view: (
        <InfoLayout>
          <InfoAppliancesPage />
        </InfoLayout>
      )
    })
  ),
  '/404': gtmDataLayer(
    route({
      view: <Errors400 status={400} />
    })
  )
})

const Routes = () => {
  const {state, actions} = useAppState()
  const user = state.currentUser
  useEffect(() => {
    console.log('is init', i18next.isInitialized)
  })
  //const isSuperUserOnlyView = state.me?.accessRights.superuser === true && state.selectedChainId === undefined
  return user !== null ? (
    <Router routes={routeData} context={{currentUser: user, actions, state, forcePasswordResetUser: undefined}}>
      <NotFoundBoundary render={Errors400}>
        <View />
      </NotFoundBoundary>
    </Router>
  ) : (
    <Router
      routes={routeData}
      context={{
        currentUser: undefined,
        actions,
        forcePasswordResetUser: state.forcePasswordResetUser
      }}
    >
      <NotFoundBoundary render={Errors400}>
        <View />
      </NotFoundBoundary>
    </Router>
  )
}

const Layout = ({children}: {children: ReactNode}) => {
  const loading = useLoadingRoute()
  return (
    <MainContentContainer id="main-content">
      <Loader show={!!loading} />
      {children}
    </MainContentContainer>
  )
}

const NoMenuLayout = ({children}: {children: ReactNode}) => {
  const loading = useLoadingRoute()
  return (
    <NoMenuContentContainer id="main-content">
      <Loader show={!!loading} />
      {children}
    </NoMenuContentContainer>
  )
}

export const Loader = ({show}: {show: boolean}) => {
  useEffect(() => {
    console.log('loading shown')
    return () => {
      console.log('loading away')
    }
  }, [])
  return show
    ? createPortal(
        <div
          style={{
            height: '100vh',
            width: '100vw',
            backgroundColor: 'white',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            color: 'white',
            display: 'flex'
          }}
        >
          <Puff stroke="black" />
        </div>,
        document.getElementById('portal')!
      )
    : null
}

export default Routes
