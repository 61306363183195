import React, {useEffect} from 'react'
import {Title, P, Text} from '../../Atoms/Typography'
import {LabelInput} from '../../Atoms/Forms'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {InvisibleContainer, Baseline, Row} from '../../Layout/Grid'
import {Button, TextButton} from '../../Atoms/Buttons'
import {useAppState} from '../../../state'
import {useNavigation} from 'react-navi'
import {colors} from '../../../sharedComponents/colors'
import {LoginLogo, TitleWrapper} from './LoginComponents'
import {Helmet} from 'react-navi-helmet-async'

interface Form {
  password: string
  repeatPassword: string
}
interface Creds {
  email?: string
}
const ForceResetPassword = (creds: Creds) => {
  const navi = useNavigation()
  const fc = useForm<Form>({mode: 'onChange'})
  const {actions, state} = useAppState()
  const {t} = useTranslation(['auth', 'users'])

  useEffect(() => {
    if (!state.forcePasswordResetUser) {
      navi.navigate('/') // do not enter this page, unless there's the cognitoUser with FORCE_PASSWORD_CHANGE detected and stored in state
    }
  }, [state.forcePasswordResetUser])

  const handleForceReset = () => {
    const {password, repeatPassword} = fc.watch()
    if (!password || password.length < 8) {
      fc.setError(
        'password',
        'custom',
        t('common:validation.passwordTooShort', 'New password is too short, must be at least 8 characters')
      )
      return
    }
    if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) {
      fc.setError(
        'password',
        'custom',
        t('common:validation.passwordFormatWrong', 'New password must contain both lowercase and uppercase letters')
      )
      return
    }
    if (password !== repeatPassword) {
      fc.setError('repeatPassword', 'custom', t('common:validation.passwordsNotMatching', 'New passwords must match'))
      return
    }
    actions
      .completeForceResetPassword({newPassword: password, email: creds.email!})
      .then(async () => {
        console.log('completeForceResetPassword done')
        await actions.reloadApp() // user is now authenticated in cognito > reload the app to update the state
        actions.cancelForcePasswordReset() // to navigate away from this page
      })
      .catch(e => {
        console.log('completeForceResetPassword error', e)
        if (e.code === 'InvalidPasswordException') {
          // password not long/complex enough
          fc.setError(
            'password',
            'custom',
            t('common:validation.passwordFormatWrong', 'New password must contain both lowercase and uppercase letters')
          )
        } else {
          fc.setError('password', 'custom', t('common:general.unknownError', 'Unknown error'))
        }
      })
  }

  const handleCancel = () => {
    actions.cancelForcePasswordReset()
  }

  return (
    <InvisibleContainer>
      <Helmet title={t('common:routes.resetPassword', 'Reset password')} />
      <LoginLogo />
      <TitleWrapper>
        <Title level={2} color={colors.system.white}>
          {t('auth:labels.resetPassword', 'Reset password')}
        </Title>
      </TitleWrapper>
      <Baseline>
        <P color={colors.system.white}>
          {t(
            'auth:messages.forceResetPasswordInstructions',
            `Since you're logging in for the first time, or your password has been reset by manager, you are required to change your password now.`
          )}
        </P>
        <LabelInput
          negative
          autoComplete="new-password"
          type="password"
          labelText={t('auth:labels.newPassword', 'New password')}
          name="password"
          id="password"
          ref={fc.register({required: true})}
          errorMsg={!!fc.errors.password ? fc.errors.password!.message : undefined}
        />
        <LabelInput
          negative
          autoComplete="new-password"
          type="password"
          labelText={t('users:labels.repeatNewPassword', 'Repeat new password')}
          name="repeatPassword"
          id="repeatPassword"
          ref={fc.register({required: true})}
          errorMsg={!!fc.errors.repeatPassword ? fc.errors.repeatPassword!.message : undefined}
        />
        <Text level={2} color={colors.system.white}>
          {t(
            'common:validation.passwordLength',
            'Your password must contain minimum 8 characters, lowercase letters, and uppercase letters.'
          )}
        </Text>
        <Row>
          <TextButton negative onClick={handleCancel}>
            {t('auth:labels.backToLogin', 'Back to login')}
          </TextButton>
        </Row>
        <Button disabled={!fc.formState.isValid} onClick={handleForceReset}>
          {t('auth:labels.resetPassword', 'Reset password')}
        </Button>
      </Baseline>
    </InvisibleContainer>
  )
}

export default ForceResetPassword
