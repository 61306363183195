import React, {useState, CSSProperties} from 'react'
import {colors} from '../../sharedComponents/colors'
import {IconButton} from '../Atoms/Buttons'
import {IconClose, IconSearch} from '../../Assets/Icons/TinyIcons'

const styles: {[key: string]: CSSProperties} = {
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px 22px'
  },
  searchBar: {
    borderStyle: 'solid',
    borderRadius: '4px',
    fontSize: '14px',
    color: colors.brand.black,
    flex: 1,
    height: '35px',
    borderColor: 'gray',
    borderWidth: '1px',
    marginLeft: '10px',
    paddingLeft: '10px',
    backgroundColor: colors.system.white,
    width: '45%'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginLeft: '7px'
  }
}

interface SearchRowProps {
  setSearchQuery: (query: string) => void
  placeholderText: string
  backgroundColor?: string
}

export const SearchRow: React.FC<SearchRowProps> = ({
  setSearchQuery,
  placeholderText,
  backgroundColor = colors.system.white
}) => {
  const [query, setQuery] = useState('')

  const clearAllInput = () => {
    setSearchQuery('')
    setQuery('')
  }
  return (
    <div style={{...styles.searchContainer, backgroundColor}}>
      <input
        style={styles.searchBar}
        value={query}
        onChange={e => setQuery(e.target.value)}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            setSearchQuery(query)
          }
        }}
        placeholder={placeholderText}
      />
      <div style={styles.buttonContainer}>
        <IconButton
          buttonProps={{
            onClick: e => {
              e.preventDefault()
              e.stopPropagation()
              setSearchQuery(query)
            }
          }}
          icon={<IconSearch color={colors.system.grey_1} />}
        />
        <IconButton
          buttonProps={{
            onClick: e => {
              e.preventDefault()
              e.stopPropagation()
              clearAllInput()
            }
          }}
          icon={<IconClose color={colors.system.grey_1} />}
        />
      </div>
    </div>
  )
}
export default SearchRow
